import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Menu de navegação',
    isTitle: true
  },
  {
    id: 2,
    label: 'Fluxos RPA',
    icon: 'mdi mdi-robot',
    subItems: [
      {
        id: 3,
        label: 'Liquidez',
        link: '/rpa/liquidez',
        parentId: 2
      },
      {
        id: 4,
        label: 'Contabilidade',
        subItems: [
          {
            id: 5,
            label: 'Realizar Importações',
            link: '/rpa/contabilidade/realizar-importacoes',
            parentId: 4
          },
          {
            id: 6,
            label: 'Desfazer Importações',
            link: '/rpa/contabilidade/desfazer-importacoes',
            parentId: 4
          }
        ],
        parentId: 2
      },
    ]
  },
  {
    id: 7,
    label: 'Configurações',
    icon: 'mdi mdi-cogs',
    subItems: [
      {
        id: 8,
        label: 'Rubricas',
        link: '/config/rubricas',
        parentId: 7
      },
      {
        id: 5,
        label: 'Centros de Custos',
        link: '/config/centro-de-custos',
        parentId: 7
      },
    ]
  }
];

